import { create } from 'zustand'

export enum ConsentType {
  AGREE = 'agree',
  WITHDRAWAL = 'withdrawal',
  LATER = 'later',
}

export enum ConsentTextType {
  HEADER = "header",
  ONLINE = "online",
  OWN_HANDS = "own_hands",
}

export enum EmailVerifiedState {
  SUBMITTED = "submitted",
  SENT = "sent",
  VERIFIED = "verified",
}

interface ConsentOnlineText {
  id: number
  text: string
  type: string
  validTo: Date
}

interface ConsentStore {
  email: string
  setEmail: (email: string) => void
  phone: string
  setPhone: (phone: string) => void
  consentType: ConsentType | null | undefined
  setConsentType: (consentType: ConsentType | null) => void
  consentOnlineText: ConsentOnlineText | null
  setConsentOnlineText: (consentOnlineText: ConsentOnlineText) => void
  consentOwnHandsText: ConsentOnlineText | null
  setConsentOwnHandsText: (consentOwnHandsText: ConsentOnlineText) => void
  consentOnlineCreatedById: number | null
  setConsentOnlineCreatedById: (consentOnlineCreatedById: number) => void
  consentDialogOpen: boolean
  setConsentDialogOpen: (consentDialogOpen: boolean) => void
  consentOnlineDeletedAt: Date | null
  setConsentOnlineDeletedAt: (consentOnlineDeletedAt: Date) => void
  consentOnlineCreatedAt: Date | null
  setConsentOnlineCreatedAt: (consentOnlineCreatedAt: Date) => void
  consentOwnHandsCreatedAt: Date | null
  setConsentOwnHandsCreatedAt: (consentOnlineCreatedAt: Date) => void
  emailVerifyCodeExpires: Date | null
  setEmailVerifyCodeExpires: (emailVerifyCodeExpires: Date) => void
  emailVerifiedState: EmailVerifiedState | null
  setEmailVerifiedState: (emailVerifiedState: EmailVerifiedState) => void
}

const useConsentStore = create<ConsentStore>((set) => ({
  email: '',
  setEmail: (email: string) => set({ email }),

  phone: '',
  setPhone: (phone: string) => set({ phone }),

  consentType: undefined,
  setConsentType: (consentType: ConsentType | null) => set({ consentType }),

  consentOnlineText: null,
  setConsentOnlineText: (consentOnlineText: ConsentOnlineText) => set({ consentOnlineText }),

  consentOwnHandsText: null,
  setConsentOwnHandsText: (consentOwnHandsText: ConsentOnlineText) => set({ consentOwnHandsText }),

  consentOnlineCreatedById: null,
  setConsentOnlineCreatedById: (consentOnlineCreatedById: number) => { set({ consentOnlineCreatedById }) },

  consentDialogOpen: false,
  setConsentDialogOpen: (consentDialogOpen: boolean) => set({ consentDialogOpen }),

  consentOnlineDeletedAt: null,
  setConsentOnlineDeletedAt: (consentOnlineDeletedAt: Date) => set({ consentOnlineDeletedAt }),

  consentOnlineCreatedAt: null,
  setConsentOnlineCreatedAt: (consentOnlineCreatedAt: Date) => set({ consentOnlineCreatedAt }),

  consentOwnHandsCreatedAt: null,
  setConsentOwnHandsCreatedAt: (consentOwnHandsCreatedAt: Date) => set({ consentOwnHandsCreatedAt }),

  emailVerifyCodeExpires: null,
  setEmailVerifyCodeExpires: (emailVerifyCodeExpires: Date) => set({ emailVerifyCodeExpires }),

  emailVerifiedState: null,
  setEmailVerifiedState: (emailVerifiedState: EmailVerifiedState) => set({ emailVerifiedState }),
}))

export default useConsentStore
