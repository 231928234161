import { useEffect, useState } from "react";
import gql from "graphql-tag";
import { Grid2 as Grid, Button, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Tooltip } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useMask, format, unformat } from "@react-input/mask";
import CancelConsent from "./ConsentCancel";
import useConsentStore, { EmailVerifiedState } from "../../store/consentStore";
import { useMutation } from "@apollo/client";
import ForbiddenDomainList from "../../utils/ForbiddenDomainList";
import { useLocation } from "react-router-dom";
import { DateTime } from 'luxon';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

interface ConsentFormValuesType {
  phone: string;
  email: string;
}

interface ConsentFormErrorsType {
  phone?: string;
  email?: string;
}

const UPDATE_CONSENT_ONLINE_CONTACT_INFO = gql`
  mutation UpdateConsentOnlineContactInfo($email: String, $phone: String, $textId: Int, $clientName: ClientName) {
    updateConsentOnlineContactInfo(email: $email, phone: $phone, textId: $textId, clientName: $clientName) {
      id
      email
      phone
      ico
      userId
      text {
        id
        text
        type
        validTo
      }
    }
  }
`;

const CONFIRM_EMAIL_CONSENT_ONLINE = gql`
  mutation confirmEmailConsentOnline($emailVerifyCode: String!) {
    confirmEmailConsentOnline(emailVerifyCode: $emailVerifyCode)
  }
`;

const RESEND_EMAIL_VERIFY_CODE = gql`
  mutation resendVerificationEmailConsentOnline {
    resendVerificationEmailConsentOnline
  }
`;

const ConsentForm = () => {
  const {
    email,
    phone,
    consentOnlineText,
    consentOnlineCreatedById,
    setConsentDialogOpen,
    emailVerifyCodeExpires,
    emailVerifiedState,
  } = useConsentStore();

  const [confirmEmailConsentOnline] = useMutation(CONFIRM_EMAIL_CONSENT_ONLINE, {
    refetchQueries: ["consentOnline"],
  });

  const [resendVerificationEmailConsentOnline, {
    loading: resendVerificationEmailConsentOnlineLoading
  }] = useMutation(RESEND_EMAIL_VERIFY_CODE, {
    refetchQueries: ["consentOnline"],
  });

  const [verificationError, setVerificationError] = useState<string | null>(null);
  const [verificationSuccess, setVerificationSuccess] = useState<boolean>(false);

  const handleCloseVerificationError = () => {
    setVerificationError(null);
    window.history.replaceState({}, '', window.location.pathname);
  };

  const handleCloseVerificationSuccess = () => {
    setVerificationSuccess(false);
    window.history.replaceState({}, '', window.location.pathname);
  };

  const location = useLocation();

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const phoneMaskOptions = {
    mask: '+(___) ___-___-___',
    replacement: { _: /\d/ },
    showMask: true,
  }

  const inputPhoneRef = useMask(phoneMaskOptions);
  const defaultPhoneValue = format(phone, phoneMaskOptions);

  const validation = (values: ConsentFormValuesType) => {
    const errors: ConsentFormErrorsType = {};
    const requiredMsg = "Pole musí být vyplněno";
    const errorPhoneMessage = "Neplatné telefonní číslo";
    const unformatedPhone = unformat(values.phone, phoneMaskOptions);

    const forbiddenDomains = ForbiddenDomainList;

    if (!values.email) {
      errors.email = requiredMsg;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Neplatný email";
    } else {
      const emailDomain = values.email.split("@")[1]?.toLowerCase();
      if (forbiddenDomains.includes(emailDomain)) {
        errors.email = "Musíte použít soukromý email";
      }
    }

    if (!unformatedPhone || unformatedPhone.length < 12) {
      errors.phone = requiredMsg;
    } else {
      const countryCode = unformatedPhone.slice(0, 3);
      if (countryCode === "420") {
        if (!/^420[67]\d{8}$/.test(unformatedPhone)) {
          errors.phone = errorPhoneMessage;
        }
      } else if (countryCode === "421") {
        if (!/^4219\d{8}$/.test(unformatedPhone)) {
          errors.phone = errorPhoneMessage;
        }
      } else {
        errors.phone = errorPhoneMessage;
      }
    }

    return errors;
  };

  const [updateConsentOnlineContactInfo] = useMutation(UPDATE_CONSENT_ONLINE_CONTACT_INFO, {
    refetchQueries: "all",
  });

  const handleUpdateContactInfo = async ({
    email,
    phone,
    textId,
    clientName,
  }: {
    email: string;
    phone: string;
    textId: number | null;
    clientName: string;
  }) => {
    await updateConsentOnlineContactInfo({
      variables: {
        email,
        phone,
        textId,
        clientName,
      },
    });
  };

  const handleFormSubmit = async (
    values: ConsentFormValuesType,
    setSubmitting: {
      (isSubmitting: boolean): void;
    }) => {
    const { phone: _phone, email: _email } = values;
    const unformatedPhone = unformat(_phone, phoneMaskOptions);

    setIsFormSubmitted(false);

    try {
      setSubmitting(false);
      setIsFormSubmitted(true);

      await handleUpdateContactInfo({
        email: _email,
        phone: unformatedPhone,
        textId: consentOnlineText?.id ?? null,
        clientName: "METRO",
      });

      if (!email && !phone) {
        setConsentDialogOpen(true);
      }
    } catch (error) {
      console.error("Error updating contact info:", error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    let code = "";

    if (location.search.includes('verifyEmail')) {
      const urlSearchParams = new URLSearchParams(location.search);
      code = urlSearchParams.get('verifyEmail') || "";

      if (code) {
        confirmEmailConsentOnline({ variables: { emailVerifyCode: code } })
          .then(() => {
            setVerificationSuccess(true);
          })
          .catch((error) => {
            if (error.message.includes('expired')) {
              setVerificationError('Kód pro ověření e-mailu vypršel. Požádej o nový kód pro ověření.');
            } else if (error.message.includes('Invalid')) {
              setVerificationError('Kód pro ověření e-mailu je neplatný.');
            } else {
              setVerificationError('Došlo k chybě při ověřování vašeho e-mailu. Zkus to prosím znovu.');
            }
          });
      }
    }
  }, [confirmEmailConsentOnline, location]);

  return (
    <>
      <Dialog
        open={verificationSuccess}
        onClose={handleCloseVerificationSuccess}
        aria-labelledby="verification-success-dialog-title"
      >
        <DialogTitle id="verification-success-dialog-title" color="success">
          E-mail úspěšně ověřen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vaše e-mailová adresa byla úspěšně ověřena. Nyní můžete pokračovat v procesu.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button onClick={handleCloseVerificationSuccess} variant="contained" color="primary">
            Pokračovat
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!verificationError}
        onClose={handleCloseVerificationError}
        aria-labelledby="verification-error-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            padding: "1rem",
          },
        }}
      >
        <DialogTitle id="verification-error-dialog-title" color="error">
          Chyba ověření e-mailu
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {verificationError}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button onClick={handleCloseVerificationError} variant="contained" color="primary">
            Zpět na stránku souhlasu
          </Button>
        </DialogActions>
      </Dialog>

      <Formik
        enableReinitialize
        validate={validation}
        initialValues={{
          email,
          phone: defaultPhoneValue,
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Grid
              container
              maxWidth={700}
              mx="auto"
              mb={2}
            >
              <Grid
                size={{ xs: 12, md: 6 }}
                textAlign={{ xs: "left", md: "right" }}
                px={{
                  xs: 0,
                  md: 1,
                }}
                mt={{
                  xs: 0,
                  md: 2,
                }}
              >
                <label htmlFor="email" style={{
                  fontWeight: "bold",
                }}>Soukromá e-mailová adresa:</label>
              </Grid>

              <Grid
                size={{ xs: 12, md: 6 }}
              >
                <Field
                  style={{
                    marginBottom: 8,
                  }}
                  slotProps={
                    consentOnlineCreatedById !== 1 ?
                      {
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                title={
                                  emailVerifiedState !== EmailVerifiedState.VERIFIED
                                    ? "Neověřená e-mailová adresa"
                                    : "Ověřená e-mailová adresa"
                                }
                              >
                                {
                                  emailVerifiedState !== EmailVerifiedState.VERIFIED
                                    ? (
                                      <UnpublishedOutlinedIcon color="warning" />
                                    )
                                    : (
                                      <CheckCircleOutlineOutlinedIcon color="success" />
                                    )
                                }
                              </Tooltip>
                            </InputAdornment>
                          ),
                        },
                      } : null
                  }
                  id="email"
                  component={TextField}
                  name="email"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              maxWidth={700}
              mx="auto"
              mb={2}
            >
              <Grid
                size={{ xs: 12, md: 6 }}
                textAlign={{ xs: "left", md: "right" }}
                px={{
                  xs: 0,
                  md: 1,
                }}
                mt={{
                  xs: 0,
                  md: 2,
                }}
              >
                <label htmlFor="phone" style={{
                  fontWeight: "bold",
                }}>Telefonní číslo:</label>
              </Grid>

              <Grid
                size={{ xs: 12, md: 6 }}
              >
                <Field
                  style={{
                    marginBottom: 8,
                  }}
                  id="phone"
                  component={TextField}
                  inputRef={inputPhoneRef}
                  placeholder="+(___) ___-___-___"
                  name="phone"
                  type="tel"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              maxWidth={700}
              mx="auto"
              mb={2}
              rowGap={2}
            >
              {isFormSubmitted && (
                <Grid
                  size={12}
                  textAlign="left"
                >
                  <Alert
                    severity="success"
                  >
                    Údaje byly úspěšně uloženy
                  </Alert>
                </Grid>
              )}

              <Grid
                size={{ xs: 12, md: 6 }}
                textAlign="right"
                alignContent="center"
                px={{
                  xs: 0,
                  md: 1,
                }}
              >
                <CancelConsent />
              </Grid>

              <Grid
                size={{ xs: 12, md: 6 }}
                textAlign={{ xs: "right", md: "left" }}
              >

                <Button
                  disabled={
                    isSubmitting
                    || (values.email === email
                      && values.phone === defaultPhoneValue
                      && !consentOnlineCreatedById
                    )
                  }
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="success"
                >
                  Uložit a potvrdit údaje
                </Button>
              </Grid>
            </Grid>

            {
              emailVerifiedState !== EmailVerifiedState.VERIFIED
              && emailVerifyCodeExpires
              && DateTime.fromISO(emailVerifyCodeExpires.toString()).toJSDate() < new Date()
              && (
                <Grid
                  container
                  alignItems="center"
                  width="100%">
                  <Grid
                    size={12}
                    textAlign="center"
                    mb={2}
                  >
                    <Alert
                      severity="warning"
                    >
                      Kód pro ověření e-mailu vypršel. Požádej o nový kód pro ověření.
                    </Alert>
                  </Grid>

                  <Grid
                    size={12}
                    textAlign="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={resendVerificationEmailConsentOnlineLoading}
                      onClick={() => resendVerificationEmailConsentOnline()}
                    >
                      Poslat nový kód pro ověření e-mailu
                    </Button>
                  </Grid>
                </Grid>
              )}

            {
              email
              && emailVerifiedState !== EmailVerifiedState.VERIFIED
              && emailVerifyCodeExpires
              && DateTime.fromISO(emailVerifyCodeExpires.toString()).toJSDate() > new Date()
              && (
                <Grid
                  container
                  alignItems="center"
                  width="100%">
                  <Grid
                    size={12}
                    textAlign="center"
                    mb={2}
                  >
                    <Alert
                      severity="info"
                    >
                      {
                        emailVerifiedState === EmailVerifiedState.SUBMITTED && (
                          "Na e-mailovou adresu bude odeslán odkaz pro ověření e-mailu."
                        )
                      }
                      {
                        emailVerifiedState === EmailVerifiedState.SENT && (
                          "Na e-mailovou adresu byl odeslán odkaz pro ověření e-mailu."
                        )
                      }
                    </Alert>
                  </Grid>
                </Grid>
              )
            }
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConsentForm;
